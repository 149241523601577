
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.topNavigation {
  display: none;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  gap: 2rem;

  @include respond-to($lg-up) {
    display: flex;
  }

  &--isAuthenticated {
    gap: 1.5rem;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 21px;
    white-space: nowrap;

    /* 'Sell an item' randomly has a different gap, we add the difference here */
    &--sellAnItem {
      margin: 0 0.25rem;
    }

    &--favorites {
      --size: 3rem;
      width: var(--size);
      height: var(--size);
    }
  }

  &__item {
    display: inline-flex;
    position: relative;
  }

  &__icon {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
      --size: 28px;
      display: flex;
      width: var(--size);
      height: var(--size);
    }
  }

  &__image {
    & img {
      display: flex;
      --size: 32px;
      height: var(--size);
      width: var(--size);
      border-radius: 50%;
      object-fit: cover;
    }
  }

  // TODO: We should have the reusuable bubble for counters (cart and NC's tabs)
  &__count {
    z-index: 2;
    display: flex;
    height: 1rem;
    width: auto;
    min-width: 1rem;
    padding: 0 0.25rem;
    justify-content: center;
    align-items: center;
    border-radius: 6.25rem;
    background-color: $vc-color-brand;
    color: white;
    text-align: center;
    font-family: $vc-font-body;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 0.875rem;
    position: absolute;
    right: 50%;
    bottom: -50%;
    transform: translate(100%, -80%);
  }
}
