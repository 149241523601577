
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.bottomNavigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 32px;
  max-height: 74px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #ebebeb;
  background: #fff;
  z-index: $navigation-depth;
  @include respond-to($lg-up) {
    display: none;
  }

  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1 1;
  }

  &__item {
    display: flex;
    position: relative;
    flex: 1 1;
    justify-content: center;
  }

  &__icon {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
      --size: 26px;
      display: flex;
      width: var(--size);
      height: var(--size);
    }
  }

  &__title {
    display: flex;
    overflow: hidden;
    margin-top: 5px;
    color: #1e1e1e;
    font-size: 12px;
    line-height: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--active {
      font-weight: bold;
    }
  }

  &__count {
    z-index: 2;
    display: flex;
    height: 1rem;
    width: auto;
    min-width: 1rem;
    padding: 0 0.25rem;
    justify-content: center;
    align-items: center;
    border-radius: 6.25rem;
    background-color: $vc-color-brand;
    color: white;
    text-align: center;
    font-family: $vc-font-body;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 0.875rem;
    position: absolute;
    top: 0;
    right: 20%;
  }
}
