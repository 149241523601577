
        @import "./src/styles/global.scss";
        $cdnFolder: "https://assets.staging.vestiairecollective.com/a/fenx";
      
.cart {
  position: relative;
  height: 2rem;
  min-width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include respond-to($lg-up) {
    margin-left: -0.3rem; // A bit hacky, but it's to make sure spaces between desktop buttons are equal
  }
  &__cartIcon {
    display: flex;
    position: relative;
    overflow: visible;
    width: 28px;
    height: 28px;
  }
  // TODO: We should have the reusuable bubble for counters (notifications and NC's tabs)
  &__count {
    position: absolute;
    right: 50%;
    bottom: -50%;
    transform: translate(100%, -70%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1rem;
    width: auto;
    min-width: 1rem;
    padding: 0 0.25rem;
    border-radius: 6.25rem;
    background-color: $vc-color-brand;
    color: white;
    text-align: center;
    font-family: $vc-font-body;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 0.875rem;
  }
  &__previewBoxContent:focus {
    outline: none;
  }
}
